<template>
  <NavQuestion
    v-bind:heading="'Do you want us to print your Will? '"
    v-bind:background="'bg-basket'"
    v-bind:subHeading="
      'For a one off payment of ' +
      printingPrice +
      ' we print, bind and post your Will to you ready for signing. Without this printing option you will need to download and print your Will once it has been checked.'
    "
    v-bind:number="questionNumber"
    v-bind:questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionLarge
        v-bind:letter="'a'"
        v-bind:selected="printing === true"
        v-bind:option="'Yes'"
        v-bind:pricing="printingPrice"
        v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/button-basket-printing.png'"
        @selected="setYes"
      >
        <template v-slot:text>
          <div class="pricing-sub-text">
            We will print, bind and post you the finished documents ready for
            signing (we provide two copies, one to sign and one to keep for your
            records).
          </div>
        </template>
      </InputOptionLarge>

      <InputOptionLarge
        v-bind:letter="'b'"
        v-bind:selected="printing === false"
        v-bind:option="'No'"
        v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/button-basket-no-printing.png'"
        @selected="setNo"
      >
        <template v-slot:text>
          Once your Will has been checked a PDF file will be available for you
          to download, print and sign.
        </template>
      </InputOptionLarge>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="validatePage"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="'/checking'"
        v-bind:backLink="backLink"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import NavQuestion from '@/common/ui/NavQuestion'
import InputOptionLarge from '@/common/ui/InputOptionLarge'
import { checkoutHelpers } from '@/common/mixins/checkoutHelpers'

export default {
  name: 'PrintingPage',
  mixins: [checkoutHelpers],
  components: {
    NavQuestionButtons,
    NavQuestion,
    InputOptionLarge
  },
  data() {
    return {
      printing: null
    }
  },
  watch: {
    basket(value) {
      this.storage = value
    }
  },
  computed: {
    backLink() {
      if (this.$store.getters.checkoutBasket.active_subscription)
        return '/create_will'
      return '/storage'
    },
    validatePage() {
      if (this.printing === true || this.printing === false) return true
      return false
    },
    basket() {
      return this.$store.getters.checkoutBasket.printing
    },
    printingPrice() {
      return '£' + this.$store.getters.prices.printing / 100
    },
    questionNumber() {
      if (this.$store.getters.checkoutBasket.active_subscription) return '1'
      return '2'
    }
  },
  mounted() {
    this.$store.commit('checkoutProgress', {
      createWill: true,
      package: true
    })
    this.printing = this.$store.getters.checkoutBasket.printing
    if (!this.$store.getters.willPreview) {
      this.$store.dispatch('willPreviewFetch')
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveCheckoutOptions()
    next()
  },
  methods: {
    setYes() {
      this.printing = true
      this.$store.commit('checkoutBasket', {
        printing: true
      })
    },
    setNo() {
      this.printing = false
      this.$store.commit('checkoutBasket', {
        printing: false
      })
    }
  }
}
</script>
